import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import ProcessCard from "../../components/cards/ProcessCard"
import Collapsed from "../../components/controls/Collapsed"

import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import StatusAlert from "../../components/alerts/StatusAlert"
import SessionCard from "../../components/cards/SessionCard"


import { useState } from "react"



export default function Pages({data}) {

return (
  <>
  <Layout>
  <Container>
  {isLoggedIn() ? (

 <div className="">

 {data.airtable.data.Name}
  
 </div>
 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </Container>
  </Layout>
    </>

  )
}

export const Head = () => <title>Methods</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Methods" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
      }
    }
  }
`